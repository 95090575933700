import React from "react";
import { Route, Routes } from "react-router";

// Pages
import Accueil from './../views/accueil/Accueil'
import NotFound from './../views/404/NotFound'

const AppRoutes = () => {
    return (
      <Routes>
        <Route exact path="/" element={<Accueil />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  };
  
  export default AppRoutes;