import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";

// Assets
import Image from "./../../assets/image.png";
import Diner from "./../../assets/dinner.png";
import FastTime from "./../../assets/fast-delivery.png";
import Award from "./../../assets/award.png";
import User from "./../../assets/user.png";
import Download from "./../../assets/download.svg";
import Play from "./../../assets/play.svg";
import MockUp from "./../../assets/commande-vendeuse.png";
import Tacos from "./../../assets/taco.png";
import Ios from "./../../assets/app-store-logo.png";
import Android from "./../../assets/google-play-logo.png";
import StarOff from "./../../assets/fade-star.png";
import StarOn from "./../../assets/active-star.png";
import HandBurger from "./../../assets/hand-burger.png";
import Deco from "./../../assets/deco.svg";
import Facebook from "./../../assets/facebook.svg";
import Twitter from "./../../assets/twitter.svg";
import Linkedin from "./../../assets/linkedin.svg";
import User1 from "./../../assets/user1.png";
import User2 from "./../../assets/user2.png";
import User3 from "./../../assets/user3.png";
import axios from "axios";

const Accueil = () => {
  const [device, setDevice] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [nom, setNom] = useState("");
  const [prenoms, setPrenoms] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const validateEmail = () => {
    let res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return res.test(email);
  };

  const sendMessage = async () => {
    setError("");
    setSuccess("");
    if (nom && prenoms && email && message) {
      if (validateEmail(email)) {
        setLoading(true);
        const messageData = {
          nom,
          prenoms,
          email,
          message,
        };

        try {
          await axios
            .post(
              `http://5.196.4.7:8080/restaurantapplication-api/site/contacteznous`,
              messageData
            )
            .then((res) => {
              setNom("");
              setPrenoms("");
              setEmail("");
              setMessage("");
              setSuccess("Message envoyé avec succès");
              setError("");
              setLoading(false);
            })
            .catch((error) => {
              console.log("Something went wrong", error);
              setError("Une erreur est survenu");
              setSuccess("");
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          setError("Une erreur est survenu");
          setSuccess("");
          console.log("ERROR", error);
        }
      } else {
        setError("Entrer une email correcte");
      }
    } else {
      setLoading(false);
      setError("Renseigner tout les champs");
      console.log("renseigner tout les champs");
    }
  };

  useEffect(() => {
    if (navigator.platform.indexOf("Win") !== -1) {
      setDevice("windows");
    } else if (navigator.platform.indexOf("Mac") !== -1) {
      setDevice("mac");
    } else if (navigator.platform.indexOf("X11") !== -1) {
      setDevice("x11");
    } else if (navigator.platform.indexOf("Linux") !== -1) {
      setDevice("linux");
    } else if (navigator.platform.indexOf("iPhone") !== -1) {
      setDevice("iphone");
    } else if (navigator.platform.indexOf("Android") !== -1) {
      setDevice("android");
    } else {
      setDevice("unknow");
    }
  }, []);

  return (
    <Layout>
      <div className="w-11/12 sm:w-10/12 max-w-screen-xl mx-auto mt-28 mb-40">
        {/* Début Banner */}
        <div className="relative flex items-center justify-between sm:px-4">
          <div className="absolute hidden sm:block sm:-left-10 sm:-top-10 md:-left-5 md:-top-5 bg-[#F6D4C0]/40 w-40 h-40 rounded-full -z-10"></div>
          <div className="w-full md:w-3/5 lg:w-2/5">
            <h1 className="text-3xl sm:text-4xl font-serif font-medium">
              La solution idéale pour la gestion de la{" "}
              <span className="text-main">restauration en entreprise</span>
            </h1>
            <div className="flex items-center gap-x-4 lg:gap-x-6 mt-10">
              <img src={User} alt="personne" className="w-9 sm:w-12" />
              <p className="text-xs sm:text-sm pr-0 lg:pr-10 xl:pr-20">
                “SkanResto a changé la vie de mes employés. Depuis que nous
                l’utilisons, nos employés sont heureux et plus productifs.”
              </p>
            </div>
            <div className="mt-10 flex flex-wrap items-center justify-center sm:justify-start gap-4">
              {device === "windows" ||
              device === "android" ||
              device === "linux" ||
              device === "x11" ||
              device === "unknow" ? (
                <a
                  href="https://play.google.com/store/apps/details?id=com.skan.skanresto"
                  className="w-full sm:w-auto"
                >
                  <button className="w-full sm:w-auto flex items-center justify-center bg-main h-10 rounded-full px-4 flex items-center gap-x-2">
                    <img src={Download} alt="download icon" className="w-3" />
                    <span className="text-white text-xs font-medium">
                      Télécharger l'application
                    </span>
                  </button>
                </a>
              ) : device === "mac" || device === "iphone" ? (
                <a href="https://google.com">
                  <button className="w-full sm:w-auto flex items-center justify-center bg-main h-10 rounded-full px-4 flex items-center gap-x-2">
                    <img src={Download} alt="download icon" className="w-3" />
                    <span className="text-white text-xs font-medium">
                      Télécharger l'application
                    </span>
                  </button>
                </a>
              ) : (
                ""
              )}
              {/* <button className="w-full sm:w-auto flex items-center justify-center bg-[#232229] hover:bg-black text-black hover:text-white h-10 rounded-full px-4 flex items-center gap-x-2">
                <img src={Play} alt="play icon" className="w-3" />
                <span className="text-xs text-white font-medium">
                  Comment ça marche ?
                </span>
              </button> */}
            </div>
          </div>
          <div className="hidden md:block">
            <div className="border-2 border-[#F6D4C0] border-dashed rounded-full p-3">
              <div className="border-2 border-[#F6D4C0] border-dashed rounded-full p-3">
                <img src={Image} alt="illustration" className="w-72" />
              </div>
            </div>
          </div>
          <div className="hidden lg:flex flex flex-col gap-y-12">
            <div className="flex items-center gap-x-4">
              <div className="shadow-md rounded-full p-2">
                <img src={Diner} alt="icon diner" className="w-9" />
              </div>
              <div>
                <h4 className="font-serif text-lg">Large choix</h4>
                <p className="text-sm mt-2">
                  Accéder à des menus <br /> variés et actualisés
                </p>
              </div>
            </div>
            <div className="flex items-center gap-x-4">
              <div className="shadow-md rounded-full p-2">
                <img src={FastTime} alt="icon diner" className="w-9" />
              </div>
              <div>
                <h4 className="font-serif text-lg">Livraison rapide</h4>
                <p className="text-sm mt-2">
                  Recevez vos plats en temps et en heure{" "}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-x-4">
              <div className="shadow-md rounded-full p-2">
                <img src={Award} alt="icon diner" className="w-9" />
              </div>
              <div>
                <h4 className="font-serif text-lg">Qualité</h4>
                <p className="text-sm mt-2">
                  Hygiène et qualité des repas assurées
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-wording flex lg:hidden flex items-start justify-around gap-x-4 mt-20">
          <div className="flex items-start gap-x-4">
            <div className="shadow-md rounded-full p-2">
              <img src={Diner} alt="icon diner" className="w-[70px] md:w-14" />
            </div>
            <div>
              <h4 className="font-serif text-lg">Large choix</h4>
              <p className="text-sm mt-2">
                Accéder à des menus variés et actualisés
              </p>
            </div>
          </div>
          <div className="flex items-start gap-x-4">
            <div className="shadow-md rounded-full p-2">
              <img
                src={FastTime}
                alt="icon diner"
                className="w-[70px] md:w-14"
              />
            </div>
            <div>
              <h4 className="font-serif text-lg">Livraison rapide</h4>
              <p className="text-sm mt-2">
                Recevez vos plats en temps et en heure{" "}
              </p>
            </div>
          </div>
          <div className="flex items-start gap-x-4">
            <div className="shadow-md rounded-full p-2">
              <img src={Award} alt="icon diner" className="w-[70px] md:w-14" />
            </div>
            <div>
              <h4 className="font-serif text-lg">Qualité</h4>
              <p className="text-sm mt-2">
                Hygiène et qualité des repas assurées
              </p>
            </div>
          </div>
        </div>
        <img
          src={Deco}
          alt="illlustration petite bulle"
          className="absolute right-0 mt-10 hidden sm:block"
        />
        {/* Fin Banner */}

        {/* Début section 2 */}
        <div className="illustration-section relative h-80 mt-24 sm:mt-36 md:mt-56 flex flex items-center justify-between">
          <img
            src={MockUp}
            alt="phone mockup"
            className="mockup w-48 sm:w-64 md:w-auto"
          />
          <img
            src={Tacos}
            alt="tacos illustration"
            className="absolute right-10 -top-36 lg:-top-24 hidden md:block"
          />
          <p className="text-4xl font-serif text-white -mt-10 lg:mt-5 mr-5 hidden md:block">
            Télécharger notre <br /> application mobile
          </p>
          <p className="mobile-text text-3xl font-serif text-white -mt-10 lg:mt-5 mr-5 block md:hidden">
            Télécharger notre application mobile
          </p>
          <div className="download-store absolute -right-10 md:right-0 bottom-2 lg:bottom-0 lg:relative flex gap-x-5 lg:self-end mr-14">
            <div className="download-store-android bg-white rounded-full lg:rounded-tr-full lg:rounded-br-none lg:rounded-tl-full lg:rounded-bl-none px-4 sm:px-0 sm:w-32 lg:w-36 h-20 sm:h-24 lg:h-40 flex flex-col justify-center items-center">
              <div className="flex flex-col items-center">
                <img
                  src={Android}
                  alt="play store logo"
                  className="w-9 sm:w-12 lg:w-auto"
                />
                <div className="flex gap-x-1">
                  <img
                    src={StarOn}
                    alt="étoile active"
                    className="w-2 sm:w-auto"
                  />
                  <img
                    src={StarOn}
                    alt="étoile active"
                    className="w-2 sm:w-auto"
                  />
                  <img
                    src={StarOn}
                    alt="étoile active"
                    className="w-2 sm:w-auto"
                  />
                  <img
                    src={StarOn}
                    alt="étoile active"
                    className="w-2 sm:w-auto"
                  />
                  <img
                    src={StarOff}
                    alt="étoile inactive"
                    className="w-2 sm:w-auto"
                  />
                </div>
              </div>
              <p className="font-poppins font-bold text-xs sm:text-md text-center mt-2 lg:mt-5">
                4,0/5
              </p>
            </div>
            <div className="download-store-apple bg-white rounded-full lg:rounded-tr-full lg:rounded-br-none lg:rounded-tl-full lg:rounded-bl-none px-4 sm:px-0 sm:w-32 lg:w-36 h-20 sm:h-24 lg:h-40 flex flex-col justify-center items-center pt-5">
              <img
                src={Ios}
                alt="logo apple store"
                className="w-9 sm:w-12 lg:w-auto"
              />
              <div className="flex gap-x-1 mt-2 lg:mt-5">
                <img
                  src={StarOn}
                  alt="étoile active"
                  className="w-2 sm:w-auto"
                />
                <img
                  src={StarOn}
                  alt="étoile active"
                  className="w-2 sm:w-auto"
                />
                <img
                  src={StarOn}
                  alt="étoile active"
                  className="w-2 sm:w-auto"
                />
                <img
                  src={StarOn}
                  alt="étoile active"
                  className="w-2 sm:w-auto"
                />
                <img
                  src={StarOn}
                  alt="étoile active"
                  className="w-2 sm:w-auto"
                />
              </div>
              <p className="font-poppins font-bold text-xs sm:text-md text-center mt-2 lg:mt-4">
                5/5
              </p>
            </div>
          </div>
        </div>
        {/* Fin section 2 */}

        {/* Début section 3 */}
        <div
          id="fonctionnalite"
          className="-z-10 fontionnalite pt-32 md:pt-56 overflow-hidden"
        >
          <div className="md:flex md:justify-between overflow-hidden">
            <div className="md:w-3/5 lg:w-2/5">
              <h3 className="text-3xl sm:text-4xl font-serif font-medium">
                Nous vous fournissons repas et boissons et des services
                traiteurs au besoin
              </h3>
            </div>
            <div className="mt-10 md:mt-0 md:w-2/5 lg:w-3/6">
              <p className="text-sm sm:text-md lg:text-lg">
                Il ne s’agit juste pas de vous connecter aux meilleures
                vendeuses et de vous livrer mais bien de changer votre
                expérience de repas au travail.
              </p>
            </div>
            <div className="hand-burger hidden sm:flex absolute right-0 -mt-10 md:mt-20 items-center justify-center">
              <img
                src={HandBurger}
                alt="main tenant un burger"
                className="w-64 mt-5"
              />
            </div>
          </div>
          <div className="relative sm:grid sm:grid-cols-3 items-center mt-20 lg:px-10">
            <div className="flex gap-x-4 items-center justify-between">
              <div className="illustration-1-circle1 -z-10 border-2 border-[#F6D4C0] w-40 sm:w-auto lg:w-[20rem] border-dashed rounded-full p-1 sm:p-3 flex items-center justify-center">
                <div className="illustration-1-circle1 border-2 border-[#F6D4C0] w-40 sm:w-auto lg:w-[19rem] border-dashed rounded-full p-1 sm:p-3">
                  <img
                    src={Image}
                    alt="illustration 01"
                    className="illustration-1 w-36 sm:w-60 lg:w-72"
                  />
                </div>
              </div>
              <div className="block sm:hidden">
                <h5 className="font-serif text-3xl sm:text-5xl font-medium">
                  01
                </h5>
                <p className="text-base sm:text-md font-poppins mt-5">
                  Échangez sur les besoins de votre structure et validez la mise
                  en place de votre compte entreprise.
                </p>
              </div>
            </div>
            <div className="-ml-10 lg:ml-0 hidden sm:block">
              <h5 className="font-serif text-3xl sm:text-5xl ml-16 sm:ml-20 font-medium">
                01
              </h5>
            </div>
            <div className="-ml- hidden sm:block">
              <p className="text-base sm:text-md font-poppins">
                Échangez sur les besoins de votre structure et validez la mise
                en place de votre compte entreprise.
              </p>
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 items-center mt-5 pr-0 lg:pl-10">
            <div className="hidden sm:block">
              <h5 className="font-serif text-3xl sm:text-5xl xl:ml-20 font-medium">
                02
              </h5>
            </div>
            <div className="-ml-20 lg:-ml-32 hidden sm:block">
              <p className="text-base sm:text-md font-poppins">
                Profitez de votre espace digital SkanResto pour faire vivre une
                expérience de repas inédite à vos collaborateurs.
              </p>
            </div>
            <div className="flex gap-x-4 items-center justify-between mt-16">
              <div className="block sm:hidden">
                <h5 className="font-serif text-3xl sm:text-5xl xl:ml-20 font-medium">
                  02
                </h5>
                <p className="text-base sm:text-md font-poppins mt-5">
                  Profitez de votre espace digital SkanResto pour faire vivre
                  une expérience de repas inédite à vos collaborateurs.
                </p>
              </div>
              <div className="illustration-2-circle1 -z-10 border-2 border-[#F6D4C0] w-40 sm:w-auto lg:w-[20rem] border-dashed rounded-full p-1 sm:p-3 flex items-center justify-center">
                <div className="illustration-2-circle2 border-2 border-[#F6D4C0] w-40 sm:w-auto lg:w-[19rem] border-dashed rounded-full p-1 sm:p-3">
                  <img
                    src={Image}
                    alt="illustration 01"
                    className="illustration-2 w-36 sm:w-60 lg:w-72"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="relative sm:grid sm:grid-cols-3 items-center mt-20 lg:px-10">
            <div className="flex gap-x-4 items-center justify-between">
              <div className="illustration-3-circle1 -z-10 border-2 border-[#F6D4C0] w-40 sm:w-auto lg:w-[20rem] border-dashed rounded-full p-1 sm:p-3 flex items-center justify-center">
                <div className="illustration-3-circle2 border-2 border-[#F6D4C0] w-40 sm:w-auto lg:w-[19rem] border-dashed rounded-full p-1 sm:p-3">
                  <img
                    src={Image}
                    alt="illustration 01"
                    className="illustration-3 w-36 sm:w-60 lg:w-72"
                  />
                </div>
              </div>
              <div className="block sm:hidden">
                <h5 className="font-serif text-3xl sm:text-5xl font-medium">
                  03
                </h5>
                <p className="text-base sm:text-md font-poppins mt-5">
                  Téléchargez l’appli SkanResto, créez votre compte, et
                  commandez vos repas en toute tranquillité.
                </p>
              </div>
            </div>
            <div className="-ml-10 lg:ml-0 hidden sm:block">
              <h5 className="font-serif text-3xl sm:text-5xl ml-16 sm:ml-20 font-medium">
                03
              </h5>
            </div>
            <div className="-ml- hidden sm:block">
              <p className="text-base sm:text-md font-poppins">
                Téléchargez l’appli SkanResto, créez votre compte, et commandez
                vos repas en toute tranquillité.
              </p>
            </div>
          </div>
        </div>
        <img
          src={Deco}
          alt="illlustration petite bulle"
          className="absolute right-0 hidden sm:block"
        />
        {/* Fin section 3 */}

        {/* Début section 4 */}
        {/* <div id="equipe" className="pt-24">
          <h2 className="text-3xl sm:text-4xl text-center font-serif">
            Ils transforment vos
            <span className="text-main"> expériences de repas</span> au travail
          </h2>
          <div className="card-block grid grid-cols-2 lg:grid-cols-4 gap-x-2 mt-24">
            <div className="card-container bg-white rounded-xl h-auto px-10 sm:w-60 lg:w-52 xl:w-60 pb-7 mx-auto">
              <div className="w-24 h-24 mx-auto -mt-12 rounded-full border-2 border-[#F6D4C0] border-dashed p-1">
                <img src={User1} alt="personne" />
              </div>
              <h4 className="font-serif font-medium text-2xl text-center mt-10">
                Joshua Obi
              </h4>
              <p className="text-center text-sm mt-2">Co fondateur</p>
              <div className="flex items-center justify-center gap-x-3 mt-10">
                <img src={Facebook} alt="logo facebook" />
                <img src={Twitter} alt="logo twitter" />
                <img src={Linkedin} alt="logo linkedin" />
              </div>
            </div>
            <div className="card-container card-container-2 bg-white rounded-xl h-auto px-10 sm:w-60 lg:w-52 xl:w-60 pb-7 mx-auto">
              <div className="w-24 h-24 mx-auto -mt-12 rounded-full border-2 border-[#F6D4C0] border-dashed p-1">
                <img src={User2} alt="personne" />
              </div>
              <h4 className="font-serif font-medium text-2xl text-center mt-10">
                Kalil Tanga
              </h4>
              <p className="text-center text-sm mt-2">Co fondateur</p>
              <div className="flex items-center justify-center gap-x-3 mt-10">
                <img src={Facebook} alt="logo facebook" />
                <img src={Twitter} alt="logo twitter" />
                <img src={Linkedin} alt="logo linkedin" />
              </div>
            </div>
            <div className="card-container bg-white rounded-xl h-auto mt-20 lg:mt-0 px-10 sm:w-60 lg:w-52 xl:w-60 pb-7 mx-auto">
              <div className="w-24 h-24 mx-auto -mt-12 rounded-full border-2 border-[#F6D4C0] border-dashed">
                <img src={User2} alt="personne" />
              </div>
              <h4 className="font-serif font-medium text-2xl text-center mt-10">
                Kalil Tanga
              </h4>
              <p className="text-center text-sm mt-2">Co fondateur</p>
              <div className="flex items-center justify-center gap-x-3 mt-10">
                <img src={Facebook} alt="logo facebook" />
                <img src={Twitter} alt="logo twitter" />
                <img src={Linkedin} alt="logo linkedin" />
              </div>
            </div>
            <div className="card-container bg-white rounded-xl h-auto mt-20 lg:mt-0 px-10 sm:w-60 lg:w-52 xl:w-60 pb-7 mx-auto">
              <div className="w-24 h-24 mx-auto -mt-12 rounded-full border-2 border-[#F6D4C0] border-dashed">
                <img src={User3} alt="personne" />
              </div>
              <h4 className="font-serif font-medium text-2xl text-center mt-10">
                Helen Zahui
              </h4>
              <p className="text-center text-sm mt-2">Co fondateur</p>
              <div className="flex items-center justify-center gap-x-3 mt-10">
                <img src={Facebook} alt="logo facebook" />
                <img src={Twitter} alt="logo twitter" />
                <img src={Linkedin} alt="logo linkedin" />
              </div>
            </div>
          </div>
        </div> */}
        {/* Fin section 4 */}

        {/* Début contact section */}
        <div
          id="contact"
          className="pt-28 sm:pt-36 mb-20 lg:px-10 flex flex-col lg:flex-row items-center justify-between gap-x-6"
        >
          <div className="w-full px-5 sm:px-10 lg:px-0 lg:w-2/5">
            <div>
              <h5 className="font-serif font-medium text-3xl sm:text-5xl">
                Nos contacts
              </h5>
              <p className="text-sm sm:text-md mt-5">
                Nous sommes disponibles, n’hésitez pas à nous contacter. Nous
                vous répondons dans les 24h
              </p>
            </div>
            <div className="mt-5 sm:mt-10">
              <h5 className="font-serif font-medium text-2xl sm:text-3xl">
                Adresse
              </h5>
              <p className="text-sm sm:text-md mt-5">2 Plateaux,</p>
              <p className="text-sm sm:text-md">Abidjan, Côte d’Ivoire</p>
              {/* <p className="text-sm sm:text-md mt-2">
                Email:{" "}
                <a href="mailto:social@skanresto.com">social@skanresto.com</a>{" "}
              </p> */}
            </div>
            <div className="mt-5 flex items-center gap-x-2">
              <div className="flex items-center">
                <svg
                  fill="#000000"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 sm:w-6"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M22,3H2A1,1,0,0,0,1,4V20a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V4A1,1,0,0,0,22,3ZM21,19H3V9.477l8.628,3.452a1.01,1.01,0,0,0,.744,0L21,9.477ZM21,7.323l-9,3.6-9-3.6V5H21Z"></path>
                  </g>
                </svg>
                <span className="font-bold ml-2">Email :</span>
              </div>
              <a href="mailto:social@skanresto.com" className="text-sm sm:text-lg underline">social@skanresto.com</a>
            </div>
            {/* <div className="mt-5 sm:mt-10">
              <h5 className="font-serif font-medium text-2xl sm:text-3xl">
                Réseaux sociaux
              </h5>
              <div className="flex items-center gap-x-3 mt-5">
                <img src={Facebook} alt="logo facebook" />
                <img src={Twitter} alt="logo twitter" />
                <img src={Linkedin} alt="logo linkedin" />
              </div>
            </div> */}
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0">
            <div className="form-container bg-white w-full h-auto px-4 sm:px-10 py-10 rounded-2xl">
              {success ? (
                <p className="text-center text-sm text-green-600 font-medium bg-green-100 px-2 py-2 inline-block rounded-md mb-10">
                  {success}
                </p>
              ) : null}
              {error ? (
                <p className="text-center text-sm text-red-600 font-medium bg-red-100 px-2 py-2 inline-block rounded-md mb-10">
                  {error}
                </p>
              ) : null}
              <div className="flex gap-x-4">
                <div>
                  <p className="text-sm sm:text-md">Nom*</p>
                  <input
                    type="text"
                    id="lname"
                    name="lname"
                    className="px-3 bg-gray-100 w-full h-11 text-sm sm:text-base sm:h-12 rounded-2xl sm:rounded-full"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                  />
                </div>
                <div>
                  <p className="text-sm sm:text-md">Prénoms*</p>
                  <input
                    type="text"
                    id="lname"
                    name="lname"
                    className="px-3 bg-gray-100 w-full h-11 text-sm sm:text-base sm:h-12 rounded-2xl sm:rounded-full"
                    value={prenoms}
                    onChange={(e) => setPrenoms(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm sm:text-md">Email*</p>
                <input
                  type="email"
                  id="lname"
                  name="lname"
                  className="px-3 bg-gray-100 w-full h-11 text-sm sm:text-base sm:h-12 rounded-2xl sm:rounded-full"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-5">
                <p className="text-sm sm:text-md">Message</p>
                <textarea
                  id="lname"
                  name="lname"
                  className="px-3 py-2 bg-gray-100 w-full h-40 text-sm sm:text-base rounded-2xl"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="mt-5">
                <button
                  className="text-white text-sm sm:text-md bg-main rounded-full px-4 h-11"
                  onClick={sendMessage}
                  disabled={loading}
                >
                  {!loading ? "Envoyer mon message" : "Envoie en cours..."}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Fin contact section */}
      </div>
    </Layout>
  );
};

export default Accueil;
